<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      {{ $t('DataAccess.QueryObjects.Edit.modifyQueryObject') }}
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Name field -->
          <b-col
            cols="12"
            md="4"
          >
            <!-- cadenaConexion Input -->
            <validation-provider
              #default="{ errors }"
              name="cadenaConexionId"
              rules="required"
            >
              <b-form-group
                :label="$t('DataAccess.QueryObjects.columns.connectionString')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="queryObject.cadenaConexionId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="ConnectionStringOptions"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- objectQueryTypeId Field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="objetoConsultaTipoId"
              rules="required"
            >
              <b-form-group
                :label="$t('DataAccess.QueryObjects.columns.typeQueryObject')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="queryObject.objetoConsultaTipoId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  label="texto"
                  :options="queryObjectTypes"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- name Field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="nombre"
              rules="required"
            >
              <b-form-group
                :label="$t('DataAccess.QueryObjects.columns.name')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="queryObject.nombre"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- objetoNombre -->
          <!-- <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="objetoNombre"
              rules="required"
            >
              <b-form-group
                :label="$t('DataAccess.QueryObjects.columns.objectName')"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  v-model="queryObject.objetoNombre"
                  label="objetoConsultaNombre"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="false"
                  :options="queryObjectDatabase"
                  :reduce="option => {option.objetoConsultaNombre}"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col> -->
          <!--Parametros de paginacion en funcion-->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              v-if="queryObject.objetoConsultaTipoId === 3"
              label="Paginacion"
            >
              <b-form-checkbox
                id="multipleValuesOption"
                v-model="queryObject.enFuncionPaginacionPorParametro"
                :value="true"
                :unchecked-value="false"
              >
                <strong>Por parametro</strong>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Lists.saveChanges') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  // BFormTextarea,

  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import generalDataService from '@/services/generalData.service'
import { ref } from '@vue/composition-api'
import router from '@/router'
import vSelect from 'vue-select'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BButton,
    BFormCheckbox,
    // Form Validation
    ValidationProvider,
    ValidationObserver,

    vSelect,
  },
  setup() {
    /* SERVICES */
    const {
      fetchQueryObject,
      updateQueryObject,
      fetchConnectionStringOptions,
      fetchTypeObjectQuery,
    } = generalDataService()

    // Data
    const queryObject = ref({
      archivado: false,
      cadenaConexionId: 0,
      descripcion: '',
      esquema: '',
      listadoMostrarOrden: 0,
      nombre: '',
      objetoConsultaId: 0,
      objetoConsultaTipoId: 0,
      objetoNombre: '',
    })
    fetchQueryObject(router.currentRoute.params, data => {
      queryObject.value = data
    })
    const ConnectionStringOptions = ref([])
    fetchConnectionStringOptions(data => {
      ConnectionStringOptions.value = data
    })
    const queryObjectTypes = ref([])
    fetchTypeObjectQuery(data => {
      queryObjectTypes.value = data
    })
    /* Events */
    const onSubmit = () => {
      updateQueryObject(queryObject.value)
        .then(() => {
          router.push({ name: 'settings-dataaccess-queryobjects' })
        })
    }

    /* Validations */
    const ObjectData = ref(JSON.parse(JSON.stringify(queryObject.value)))
    const resetObjectData = () => {
      ObjectData.value = JSON.parse(JSON.stringify(queryObject))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetObjectData)
    return {
      // Data
      queryObject,
      ConnectionStringOptions,
      queryObjectTypes,
      /* Events */
      onSubmit,
      // Validations
      required,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style>

</style>
