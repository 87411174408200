var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-2",attrs:{"no-body":""}},[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('DataAccess.QueryObjects.Edit.modifyQueryObject'))+" ")]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"cadenaConexionId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('DataAccess.QueryObjects.columns.connectionString'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":false,"label":"texto","options":_vm.ConnectionStringOptions,"reduce":function (option) { return option.id; }},model:{value:(_vm.queryObject.cadenaConexionId),callback:function ($$v) {_vm.$set(_vm.queryObject, "cadenaConexionId", $$v)},expression:"queryObject.cadenaConexionId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"objetoConsultaTipoId","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('DataAccess.QueryObjects.columns.typeQueryObject'),"state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":false,"label":"texto","options":_vm.queryObjectTypes,"reduce":function (option) { return option.id; }},model:{value:(_vm.queryObject.objetoConsultaTipoId),callback:function ($$v) {_vm.$set(_vm.queryObject, "objetoConsultaTipoId", $$v)},expression:"queryObject.objetoConsultaTipoId"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('DataAccess.QueryObjects.columns.name'),"state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.queryObject.nombre),callback:function ($$v) {_vm.$set(_vm.queryObject, "nombre", $$v)},expression:"queryObject.nombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[(_vm.queryObject.objetoConsultaTipoId === 3)?_c('b-form-group',{attrs:{"label":"Paginacion"}},[_c('b-form-checkbox',{attrs:{"id":"multipleValuesOption","value":true,"unchecked-value":false},model:{value:(_vm.queryObject.enFuncionPaginacionPorParametro),callback:function ($$v) {_vm.$set(_vm.queryObject, "enFuncionPaginacionPorParametro", $$v)},expression:"queryObject.enFuncionPaginacionPorParametro"}},[_c('strong',[_vm._v("Por parametro")])])],1):_vm._e()],1)],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Lists.saveChanges'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }